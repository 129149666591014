import { Component } from '@angular/core';
import { Decimal } from 'decimal.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  data = {
    name: 'Reitan Convenience Estonia AS',
    location: '',
    bank: 'Swedbank AS',
    account: 'EE302200221001167585',
    package: '',
    date: '',
    reference: '',
    sections: ['A', 'B'],
    coins: '-',
    cash: [
      { currency: 'EUR', value: 5, quantity: 0 },
      { currency: 'EUR', value: 10, quantity: 0 },
      { currency: 'EUR', value: 20, quantity: 0 },
      { currency: 'EUR', value: 50, quantity: 0 },
      { currency: 'EUR', value: 100, quantity: 0 },
      { currency: 'EUR', value: 200, quantity: 0 },
      { currency: 'EUR', value: 500, quantity: 0 },
    ],
  };

  constructor() {
  }

  totalCash() {
    return this.data.cash.reduce((total, row) => {
      return new Decimal(total).add(new Decimal(row.value).mul(row.quantity)).toNumber();
    }, 0).toFixed(2);
  }

  totalSum() {
    return new Decimal(this.totalCash())
      .add(parseFloat(this.data.coins) || 0)
      .toFixed(2);
  }
}
